<template>
  <top-menu
    :auth="auth"
    @changeSchool="toggleChangeSchoolModal()"
  />

  <loader v-if="loading" />

  <div id="body">
    <router-view v-if="auth || isNonAuthRoute" />
    <div v-else>
      <login />
    </div>
    <sui-modal v-model="changeSchoolModalOpen">
      <sui-modal-header>Schule wechseln</sui-modal-header>
      <sui-modal-content>
        <sui-modal-description>
          <p>
            Hier können Sie die Schule wechseln.
          </p>
        </sui-modal-description>
        <form
          id="change-school-form"
          class="ui form error"
        >
          <div
            class="field"
          >
            <label>Schulk&uuml;rzel</label>
            <input
              v-model="schoolInputChangeSchool"
              type="text"
              required
              placeholder="demosfz"
            >
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button
          type="button"
          @click="toggleChangeSchoolModal"
        >
          abbrechen
        </sui-button>
        <sui-button
          type="button"
          primary
          @click="changeSchool"
        >
          Schule wechseln
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>

  <bottom-menu v-if="auth && userFullyRegistered" />
</template>

<script>
import TopMenu from "@/components/TopMenu";
import BottomMenu from "./components/BottomMenu";
import Login from "./views/Login";
import Loader from "./components/Loader";
import store from "./store";

export default {
  components: {BottomMenu, TopMenu, Login, Loader},
  data: function () {
    return {
      changeSchoolModalOpen: false,
      schoolInputChangeSchool: null
    }
  },
  computed: {
    auth() {
      return store.getters['auth/check'];
    },
    userFullyRegistered() {
      return store.getters['auth/checkFullyRegistered'];
    },
    school() {
      return store.state.auth.school;
    },
    loading() {
      return store.state.loading.active;
    },
    isNonAuthRoute() {
      return store.state.auth.nonAuthRoutes.includes(this.$route.path);
    }
  },
  created() {
    store.dispatch('config/config');
    store.dispatch('changelogModule/getCurrentVersion');
  },
  beforeMount() {
    // This event is fired, when the user tries to reload the page,
    // clicks in the URL bar and presses Enter or closes the tab (FLOG).
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    window.addEventListener("beforeunload", this.writeReloadingInformationToLocalStorage);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.writeReloadingInformationToLocalStorage);
  },
  methods: {
    toggleChangeSchoolModal: function () {
      this.changeSchoolModalOpen = !this.changeSchoolModalOpen;
    },
    changeSchool: function () {
      store.dispatch('auth/setSchool', this.schoolInputChangeSchool);
      this.toggleChangeSchoolModal();
    },
    writeReloadingInformationToLocalStorage() {
      localStorage.setItem('userReloadsMsdPortal', 'true');
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
#body {
  margin: 3rem auto 7rem;
  padding: 0 1rem 5.15rem;
  max-width: 768px;
}
/* basics */
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-1em {
  margin-bottom: 1em;
}
.margin-top-1-dot-7em {
  margin-top: 1.7em;
}
/* colors */
.color-blue {
  color: blue;
}
.color-red {
  color: rgb(204,0,51);
}
/* header */
h1,
h2,
h3,
h6 {
  text-align: center;
  margin-top: 3.1em !important;
}
h4.centered {
  text-align: center;
}
h4.margins.margin-top-large {
  margin-top: 3.1em;
}
h4.margins.no-margin-bottom {
  margin-bottom: 0;
}
h5.centered {
  text-align: center;
  margin-bottom: 0;
}
h5.margin-top-small {
  margin-top: 0.214em;
}
h5.standard-margin-top {
  margin-top: 1em;
}
form > h4 {
  text-align: left;
  margin-bottom: 1rem !important;
}
.header-margin-bottom {
  margin-bottom: 1.5em !important;
}
.header-margin-top {
  margin-top: 0.7em;
}
.subheading_font_size {
  font-size: 1.036em;
}
.position-relative {
  position: relative;
}
.ui.segment.margins.margin-top,
.ui.list.margins.margin-top {
  margin-top: 0.9em;
}
.ui.info.message {
  margin-top: 6em;
}
.segment_margin_bottom {
  margin-bottom: 0.9em !important;
}
.ui.fitted.segment.padding.all-four-paddings {
  padding: 0.5em;
}
.ui.fitted.segment.padding.padding-top-and-bottom {
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}
.ui.segments.margins.margin-top {
  margin-top: 1em;
}
.ui.button.centered,
.ui.teal.button.centered {
  margin:auto;
  display:block;
}
.form_margin_top {
  margin-top: 1.4em;
}
.text_margin_bottom {
  margin-bottom: 1.785715em;
}
.text_big_bold {
  font-weight: bold;
  font-size: 1.1rem;
}
.text_very_big_bold {
  font-weight: bold;
  font-size: 1.2rem;
}
.text_bold {
  font-weight: bold;
}
.font_weight_600 {
  font-weight: 600;
}
.text_center_opacity_06 {
  text-align: center;
  font-size: 0.95em;
  color: rgba(0, 0, 0, 0.6);
}
.input_larger_width {
  width: 112% !important;
}
/* The font-family that Fomantic UI uses for example for accordion titles */
.font-family-lato {
  font-family: Lato, system-ui, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.font_family_sans_serif {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}
.button_margin_bottom {
  margin-bottom: 0.5em !important;
}
.add-scrollbar-on-overflow {
  max-height: 60vh;
  overflow-y: auto;
}
/* Login */
.button_margins {
  margin-bottom: 1.4em !important;
  margin-top: 1.4em !important;
}
/* Lists of items */
.ui.icon.input {
  width: 100%;
  margin-top: 0.9em !important;
  margin-bottom: 0.3em !important;
}
.ui.divider.small-margin-top-and-bottom {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.ui.divider.standard-margin-top-and-bottom {
  margin-top: 1em;
  margin-bottom: 1em;
}
.ui.list .list > .item, .ui.list > .item, ol.ui.list li, ul.ui.list li {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.accordion_title_second_line {
  font-size: 0.9em;
}
.list_text_grey {
  color: grey;
  font-size: 0.9em;
}
.list_text_grey_margin_top {
  margin-top: 0.575em;
}
.list_text_margin_right_auto {
  margin-right: auto;
}
.icons_margin {
  margin-bottom: 0.1em;
  margin-left: 0.6em;
  white-space: nowrap;
}
.margin_top_0_dot_1_em {
  margin-top: 0.1em;
}
.search_bar {
  width:91% !important;
  margin-right: auto !important;
}
@media (min-width:480px) {
  .search_bar {
    width:95% !important;
  }
}
.times_icon_span {
  margin-top: 0.5em;
}
.ui.tertiary.button.times_icon_button {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0.18em !important;
}
.icons_sort_view {
  margin-top: 0.925em;
  margin-bottom: 1.2892857142857142em !important;
}
.icons_color {
  color: rgba(0,0,0,.6);
}
i.icon.betreut_von_icon {
  font-size: 1rem;
  margin-top: 0.1em;
}
i.icon.question-icon-in-segment {
  position: absolute;
  right: 10px;
  top: 10px;
  margin-right: 0;
}
.ui.button.small-margin-left-button {
  margin-left: 0.4em;
}
.ui.tertiary.icon.button.margin-left-04em {
  margin-left: 0.4em !important;
}
.ui.tertiary.icon.button.margin-left-05em {
  margin-left: 0.5em !important;
}
.ui.tertiary.icon.button.margin-left-052em {
  margin-left: 0.52em !important;
}
.ui.tertiary.icon.button.margin-left-057em {
  margin-left: 0.57em !important;
}
.ui.tertiary.icon.button.margin-left-065em {
  margin-left: 0.65em !important;
}
.ui.tertiary.icon.button.margin-left-07em {
  margin-left: 0.7em !important;
}
.ui.tertiary.icon.button.margin-left-08em {
  margin-left: 0.8em !important;
}
.ui.tertiary.icon.button.margin-left-09em {
  margin-left: 0.9em !important;
}
.ui.tertiary.icon.button.padding-left-right-01em {
  padding-left: 0.1em !important;
  padding-right: 0.1em !important;
}
.ui.tertiary.icon.button.padding-left-02em {
  padding-left: 0.2em !important;
}
.ui.tertiary.icon.button.padding-left-right-02em {
  padding-left: 0.2em !important;
  padding-right: 0.2em !important;
}
.ui.tertiary.icon.button.padding-left-right-03em {
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
.ui.tertiary.icon.button.padding-left-right-035em {
  padding-left: 0.35em !important;
  padding-right: 0.35em !important;
}
.ui.tertiary.icon.button.padding-left-right-04em {
  padding-left: 0.4em !important;
  padding-right: 0.4em !important;
}
.ui.tertiary.icon.button.padding-right-04em {
  padding-right: 0.4em !important;
}
.ui.tertiary.icon.button.padding-left-right-05em {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}
.ui.tertiary.icon.button.padding-left-05em {
  padding-left: 0.5em !important;
}
.ui.tertiary.icon.button.padding-right-05em {
  padding-right: 0.5em !important;
}
.ui.tertiary.icon.button.padding-left-right-06em {
  padding-left: 0.6em !important;
  padding-right: 0.6em !important;
}
.ui.tertiary.icon.button.padding-right-07em {
  padding-right: 0.7em !important;
}
.margin_right_0 {
  margin-right: 0 !important;
}
.ui.tertiary.button.search_icon_button {
  float: right;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0.18em !important;
}
.ui.tertiary.button.create_new_list_item_button {
  float: right;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
i.times.icon.mini_margin_left {
  margin-left: 0.4em;
}
i.address.card.icon.tiny-margin-left,
i.angle.double.up.icon.tiny-margin-left,
i.minus.circle.icon.tiny-margin-left,
i.plus.circle.icon.tiny-margin-left,
i.times.icon.tiny-margin-left {
  margin-left: 0.5em;
}
i.times.icon.small_margin_left,
i.file.export.icon.small_margin_left {
  margin-left: 0.55em;
}
i.file.export.icon.standard_margin_left,
i.lock.icon.standard_margin_left,
i.times.icon.standard_margin_left {
  margin-left: 0.6em;
}
i.lock.icon.large_margin_left {
  margin-left: 0.65em;
}
i.address.card.icon.big-margin-left {
  margin-left: 0.75em;
}
i.address.card.icon.no-margin-right,
i.angle.double.down.icon.no-margin-right,
i.angle.double.up.icon.no-margin-right,
i.archive.icon.no-margin-right,
i.caret.right.icon.no-margin-right,
i.child.icon.no-margin-right,
i.clone.icon.no-margin-right,
i.edit.icon.no-margin-right,
i.file.export.icon.no-margin-right,
i.file.upload.icon.no-margin-right,
i.hands.helping.icon.no-margin-right,
i.project.diagram.icon.no-margin-right,
i.search.icon.no-margin-right,
i.times.icon.no-margin-right {
  margin-right: 0;
}
i.file.export.icon.margin_right,
i.lock.open.icon.margin_right,
i.times.icon.margin_right {
  margin-right: 0.15em;
}
i.lock.icon.large_margin_right {
  margin-right: 0.2em;
}
i.file.export.icon.margin_top,
i.times.icon.margin_top {
  margin-top: 0.6em;
}
.cursor {
  cursor: pointer;
}
i.angle.double.down.icon.cursor,
i.angle.double.up.icon.cursor,
i.address.card.icon.cursor,
i.caret.down.icon.cursor,
i.caret.right.icon.cursor,
i.edit.icon.cursor,
i.file.export.icon.cursor,
i.hands.helping.icon.cursor,
i.large.file.pdf.outline.middle.aligned.icon.cursor,
i.lock.icon.cursor,
i.lock.open.icon.cursor,
i.pen.icon.cursor,
i.plus.icon.cursor,
i.plus.circle.icon.cursor,
i.times.icon.cursor
 {
  cursor: pointer;
}
.ui.dropdown, .ui.dropdown .menu>.item.text_bold {
  font-weight: bold;
}
.sort_dropdown {
  background-color: rgba(0, 0, 0, 0) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: -.21428571em !important;
}
.break_words {
  word-break: break-all;
}
/* Break before words. Break long words in the word */
.smart_word_break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.column_header {
  color: rgba(0,0,0,.6); font-weight: 700; font-size: 1.07em;
  margin-top: 0.7em;
}
.right_column_header_width {
  width: 11.2rem;
}
.list_dropdown {
  width: 12rem;
  margin-left: 0.5rem !important;
}
.ui.list.list_margin_top {
  margin-top: 0 !important;
}
.text_pre_wrap {
  white-space: pre-wrap;
}
/* Modal */
.modal-content {
  display: flex;
  justify-content: flex-start;
  margin-left: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
}
.modal-description {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin-right: 1em !important
}
.create_entry_modal_input_margin {
  margin-bottom: 1.2em !important;
  margin-top: 0.6rem !important;
}
.create_entry_modal_file_names {
  padding: .78571429em 1em !important;
  border-radius: .28571429rem;
  outline: 0;
  border: 1px solid rgba(34,36,38,.15);
}
.modal-actions {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(34,36,38,.15);
  background: #f9fafb;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1.5em;
  border-bottom-left-radius: .28571429rem;
  border-bottom-right-radius: .28571429rem;
}
.ui.button.create_entry_modal_buttons {
  margin-bottom: 1em;
}
.create_entry_save_changes_button {
  background-color: #00b5ad !important;
  color: #fff !important;
  width: 13.2em !important;
  flex-shrink: 0 !important;
}
.create_entry_modal_create_entry_button {
  background-color: #00b5ad !important;
  color: #fff !important;
}
.ui.modal>.actions.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* From 569 pixels, the buttons automatically begin to be arranged in two rows, which I think doesn't look good and is prevented by forming one row (FLOG) */
@media only screen and (min-width: 569px) {
  .ui.modal>.actions.button-container {
    flex-direction: row;
  }
}
.modal-button-width-small {
  width: 222px;
}
.modal-button-width-large {
  width: 242px;
}
.modal-input {
  margin-top: 0.6rem !important;
  padding: .78571429em 1em !important;
  width: 100% !important;
  border-radius: .28571429rem;
  outline: 0;
  border: 1px solid rgba(34,36,38,.15);
}
.modal-margin-bottom {
  margin-bottom: 1.2em;
}
.modal-margin-top {
  margin-top: 2em;
}
/* Menu with widgets */
.ui.centered.two.column.grid {
  width: 100%;
  margin: 0.3em 0 0;
}
.ui.grid .row {
  padding-bottom: 0.8em !important;
  padding-top: 0.8em !important;
}
a.ui.center.aligned.segment {
  width: 40%;
  margin-left: 0.9em;
  margin-right: 0.9em;
  border-width: 2px;
  color: black;
  height: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction: column;
}
.ui.center.aligned.segment.blue_widget {
  border-color: rgba(25, 88, 191, 1.0);
  background: rgba(25, 88, 191, 0.15);
}
.ui.center.aligned.segment.orange_widget {
  border-color: rgba(229, 79, 14, 1.0);
  background: rgba(229, 79, 14, 0.15);
}
.ui.center.aligned.segment.red_widget {
  border-color: rgba(219, 22, 41, 1.0);
  background: rgba(219, 22, 41, 0.15);
}
.ui.center.aligned.segment.green_widget {
  border-color: rgba(149, 144, 7, 1.0);
  background: rgba(149, 144, 7, 0.15);
}
.ui.center.aligned.segment.yellow_widget {
  border-color: rgba(249, 183, 34, 1.0);
  background: rgba(249, 183, 34, 0.15);
}
.ui.center.aligned.segment.purple_widget {
  border-color: rgba(153, 114, 192, 1.0);
  background: rgba(153, 114, 192, 0.15);
}
.ui.center.aligned.segment.foerderplan_widget {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 0.5em;
}
.ui.center.aligned.segment.transparent_widget {
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.icon_widget {
  margin-bottom: 7px !important;
}
.remove_padding {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.widget_with_status {
  margin-top: auto;
  margin-bottom: auto;
  color: black;
}
.widget_with_status_text {
  margin-bottom: 1em !important;
}
.ui.icon.button.status_button {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-right: 0;
  width: 100%;
  height: 1.4285714285714286rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 6%;
  padding-right: 6%;
}
.ui.icon.button.orange_widget {
  border-color: rgba(219, 22, 41, 1.0);
  background: rgba(219, 22, 41, 0.15);
}
.ui.icon.button.yellow_widget {
  border-color: rgba(249, 183, 34, 1.0);
  background: rgba(249, 183, 34, 0.25);
}
.wrapper_align_items_center {
  display: flex;
  align-items: center;
}
/* Flexbox */
.flexbox-center-horizontally {
  display: flex;
  justify-content: center;
}
.flexbox-justify-end-align-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flexbox-justify-end-align-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
/* Forms */
.checkbox_field {
  width: 50px !important;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
}
.ui.form .inline.fields>label.error-color {
  color: #9f3a38;
}
.ui.radio.checkbox label {
  padding-left: 1.4em !important;
}
/* Tables */
.display_table {
  display: table;
}
.display_table_cell {
  display: table-cell;
}
.ui.table>tbody>tr>td.table_cell_align {
  text-align: center;
}
.ui.table.table-margin-bottom {
  margin-bottom: 1.5em;
}
</style>
